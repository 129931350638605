/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import type { GatsbyBrowser } from 'gatsby';

import 'normalize-scss';
import './src/sass/app.scss';

import { MenuContext } from './src/contexts/MenuContext';

export const wrapPageElement: GatsbyBrowser<Queries.HamburgerMenuFragment>['wrapPageElement'] =
  ({ element, props: { data } }) => {
    if (!data.menu) {
      console.error(`Menu data not found, please add the following query to every page or template:
    menu: strapiMenu(locale: { eq: $language }) {
      items {
        id
        menu
        title
      }
    }
    `);
    }

    return (
      <MenuContext.Provider value={{ menu: data.menu }}>
        {element}
      </MenuContext.Provider>
    );
  };
